html,
body {
  margin: 0;
  padding: 0;
}

.card-container:hover {
  background-color: #f6f6f7;
}

.management-datagrid-title {
  font-weight: bold !important;
  font-size: 18px;
  background-color: #f6f6f7;
}

.management-datagrid-registration {
  background-color: #f6f6f7;
}

.management-datagrid-discount {
  font-weight: bold !important;
  white-space: normal !important; /* Allows text to wrap */
  word-break: break-word !important; /* Ensures long words break if needed */
  overflow-wrap: break-word !important; /* Alternative for better wrapping */
  text-align: left; /* Keeps the title aligned */
  background-color: #f6f6f7;
  padding: 5px; /* Adds some spacing */
  min-height: 5.2rem;
}

.custom-cell {
  cursor: pointer; /* Change cursor to pointer on cell hover */
  border: 1px solid transparent; /* Default cell border */
  padding: 6px; /* Add padding to the cell */
  transition: border-color 0.3s ease-in-out; /* Add smooth transition for border */
}

.cell {
  cursor: pointer; /* Change cursor to pointer on cell hover */
}

.apexcharts-xaxis-labels text {
  transform: rotate(-45deg);
  transform-origin: left center;
}

/* styles.css */
.scroll-left-animation {
  color: #800000;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  animation: scrollLeft 50s linear infinite; /* Default duration */
}

.selected-list-item {
  background-color: #004976 !important; /* MUI primary color */
  color: white !important;
  /* Smaller font size for list items */
}

.selected-list-item:hover {
  background-color: #1565c0 !important; /* Slightly darker on hover */
}

.selected-list-item .MuiListItemText-primary {
  font-weight: bold !important;
  font-size: 15px !important;
}

.unselected-list-item {
  background-color: transparent !important;
  color: #004976 !important; /* MUI primary color */
}

.unselected-list-item:hover {
  background-color: #e3f2fd !important; /* Light blue hover */
}

@media (max-width: 600px) {
  .scroll-left-animation {
    font-size: 14px; /* Smaller font size for mobile screens */
  }
}

.scroll-wrapper {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

/* Target the input field */
.MuiInputBase-input[type="number"]::-webkit-inner-spin-button,
.MuiInputBase-input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes scrollLeft {
  0% {
    transform: translateX(100%); /* Start from the right edge */
  }
  100% {
    transform: translateX(-100%); /* Scroll completely to the left */
  }
}

/* FUN FACTS */
@keyframes fun-fact-slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
